<template>
	<div>
		<div class="person_infor">
			<div class="login_top">
				<!-- <div class="img"> <img src="@/assets/login/flag.png"></div> -->
				<div class="login_title" @click="backClick">{{ $t('adOrder') }}</div>
				<div class="front_img">
					<img src="@/assets/mycenter/jiantou.png">
				</div>
				<div class="sub_title">{{ $t('order.orderDetail') }}</div>
			</div>
		</div>
		<div class="gap">

		</div>
		<div class="order_sn_box">
			<div class="new_order_sn">
				<div class="order">{{$t('order.orderNo')}}：{{ orderDetail.order_sn }}</div>

				<div class="top">
					<!-- 状态;-1:取消已失效;0:待付款;1:已完成 -->
					<img v-if="orderDetail.status == 0" src="@/assets/mycenter/pay.png" alt="">
					<div v-if="orderDetail.status == 0">{{$t('order.obligation')}}</div>
					<img v-if="orderDetail.status == -1" src="@/assets/mycenter/quxiao.png" alt="">
					<div v-if="orderDetail.status == -1">{{$t('order.hasCancel')}}</div>
					<img v-if="orderDetail.status == 1" src="@/assets/mycenter/wancheng.png" alt="">
					<div v-if="orderDetail.status == 1">{{$t('order.finish')}}</div>
				</div>
				<div class="btn">
					<button v-if="orderDetail.status == 0" class="cancel" @click="cancelOrderClick">{{$t('order.cancelOrder')}}</button>
					<button v-if="orderDetail.status != 0" class="del" @click="deleteOrderClick">{{$t('order.deleteOrder')}}</button>
					<button v-if="orderDetail.status == 0" class="pay" @click="toPayOrderClick">{{$t('order.toPay')}}</button>

				</div>

			</div>
			<div class="gap"></div>
			<div class="new_process">
				<div class="top">
					<div>
						<img v-if="orderDetail.status == -1" src="@/assets/mycenter/1.png" alt="">
						<img v-else src="@/assets/mycenter/1_1.png" alt="">
					</div>
					<div>
						<img v-if="orderDetail.status == 0" src="@/assets/mycenter/2.png" alt="">
						<img v-else src="@/assets/mycenter/2_1.png" alt="">
					</div>
					<div>
						<img v-if="orderDetail.status == 1" src="@/assets/mycenter/3.png" alt="">
						<img v-else src="@/assets/mycenter/3_1.png" alt="">
					</div>

				</div>
				<div class="center">
					<div class="center_box">
						<img v-if="orderDetail.status == -1" src="@/assets/mycenter/is_ok.png" alt="">
						<img v-else src="@/assets/mycenter/no_ok.png" alt="">
						<div class="border"></div>
					</div>
					<div class="center_box">
						<img v-if="orderDetail.status == 0" src="@/assets/mycenter/is_ok.png" alt="">
						<img v-else src="@/assets/mycenter/no_ok.png" alt="">
						<div class="border"></div>
					</div>
					<div>
						<img v-if="orderDetail.status == 1" src="@/assets/mycenter/is_ok.png" alt="">
						<img v-else src="@/assets/mycenter/no_ok.png" alt="">
					</div>
				</div>
				<div class="bottom">
					<div :style="{ color: orderDetail.status == -1 ? '#F39800' : '#33333' }">{{ $t('submitOrder') }}</div>
					<div class="" :style="{ color: orderDetail.status == 0 ? '#F39800' : '#33333' }">{{ $t('order.obligation') }}</div>
					<div class="pay" :style="{ color: orderDetail.status == 1 ? '#F39800' : '#33333' }">{{ $t('finishOrder') }}</div>
				</div>
			</div>

		</div>

		<!-- <div class="order_sn">
			<div class="order_sn_top">
				{{ $t('order.orderNo') }}:{{ orderDetail.order_sn }}
			</div>
			<div class="order_sn_bottom">
				<div class="order_sn_bottom_left">
					<div class="status_img">
						<img v-if="orderDetail.status == 0" src="../../../../assets/icons/status_to.png">
						<img v-if="orderDetail.status == -1" src="../../../../assets/icons/status_cancel.png">
						<img v-if="orderDetail.status == 1" src="../../../../assets/icons/status_finish.png">
					</div>
					<div class="status_text">
						<template v-if="orderDetail.status == 0">{{ $t('order.obligation') }}</template>
						<template v-if="orderDetail.status == -1">{{ $t('order.hasCancel') }}</template>
						<template v-if="orderDetail.status == 1">{{ $t('order.finish') }}</template>
					</div>
				</div>
				<div class="order_sn_bottom_right">
					<template v-if="orderDetail.status == 0">
						<div class="btn btn_grey" @click="cancelOrderClick()">
							{{ $t('order.cancelOrder') }}
						</div>
						<div class="btn btn_red" @click="toPayOrderClick()">
							{{ $t('order.toPay') }}
						</div>
					</template>
					<template v-else>
						<div class="btn btn_grey" @click="deleteOrderClick">
							{{ $t('order.deleteOrder') }}
						</div>
					</template>
				</div>
			</div>
		</div> -->
		<div class="gap">
		</div>


		<!-- <div class="step">
			<div class="step_item">
				<div class="step_item_top">
					<img src="../../../../assets/icons/step1.png">
				</div>
				<div class="step_item_middle">
					<img src="../../../../assets/icons/spot.png">
				</div>
				<div class="step_item_bottom" :style="{ color: orderDetail.status == -1 ? '#F39800' : '#33333' }">
					{{ $t('submitOrder') }}
				</div>
			</div>
			<div class="step_item">
				<div class="step_item_top">
					<img src="../../../../assets/icons/step2.png">
				</div>
				<div class="step_item_middle">
					<img src="../../../../assets/icons/spot.png">
				</div>
				<div class="step_item_bottom" :style="{ color: orderDetail.status == 0 ? '#F39800' : '#33333' }">
					{{ $t('payOrder') }}
				</div>
			</div>
			<div class="step_item">
				<div class="step_item_top">
					<img src="../../../../assets/icons/step3.png">
				</div>
				<div class="step_item_middle">
					<img src="../../../../assets/icons/spot.png">
				</div>
				<div class="step_item_bottom" :style="{ color: orderDetail.status == 1 ? '#F39800' : '#33333' }">
					{{ $t('finishOrder') }}
				</div>
			</div>
			<div class="line"></div>
		</div> -->
		<div class="gap">
		</div>
		<div class="order_detail">
			<div class="table">
				<el-table :data="orderItem" style="width: 100%" :header-cell-style="{ background: '#F6F7F9', }" border>
					<el-table-column prop="date" :label="$t('order.orderDetail')" width="633" align="center">
						<template slot-scope="scope">
							<div class="infor">
								<div class="infor_img">
									<img :src="scope.row.thumb_url">
								</div>
								<div class="infor_title">
									{{ scope.row.title }}
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="payable_money" :label="$t('money')" align="center">
					</el-table-column>
					<el-table-column :label="$t('state')" align="center">
						<template slot-scope="scope">
							<!-- 状态;-1:取消已失效;0:待付款;1:已完成 -->
							<div class="status">
								<span class="blue" v-if="scope.row.status == -1">{{ $t('order.hasCancel') }}</span>
								<span class="gray" v-if="scope.row.status == 0">{{ $t('order.obligation') }}</span>
								<span class="red" v-if="scope.row.status == 1">{{ $t('order.finish') }}</span>
							</div>
						</template>
					</el-table-column>

					<el-table-column :label="$t('operation')" align="center" width="356">
						<template slot-scope="scope">
							<div class="operate">
								<el-popconfirm :title="$t('my.deleteAllContent')" @confirm="deleteOrderClick()"
									v-if="scope.row.status != 0">
									<span class="gray" slot="reference">{{ $t('delete') }}</span>
								</el-popconfirm>
								<span class="gray" @click="cancelOrderClick()" v-if="scope.row.status == 0">{{
									$t('order.cancelOrder') }}</span>
								<!-- <span class="gray" @click="cancelOrderClick()">{{
									$t('order.cancelOrder') }}</span> -->
								<span class="blue" @click="toPayOrderClick()" v-if="scope.row.status == 0">{{
									$t('order.toPay') }}</span>
								<!-- <span class="blue" @click="toPayOrderClick()">{{
									$t('order.toPay') }}</span> -->
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<div class="order_infor">
				<div class="order_infor_title">
					{{ $t('order.orderInfor') }}
				</div>
				<div class="order_infor_content">
					<div class="order_infor_content_left">
						<div>{{ $t('order.orderNo') }}:{{ orderDetail.order_sn }}</div>
						<div>{{ $t('order.orderTime') }}:{{ orderDetail.create_date }}</div>
						<div v-if="orderDetail.status == 1">{{ $t('order.payMoney') }}:${{ orderDetail.real_money }}</div>
						<div v-if="orderDetail.status == 1">{{ $t('order.payWay') }}:{{ orderDetail.pay_type }}</div>
						<div v-if="orderDetail.status == 1">{{ $t('order.payTime') }}:{{ orderDetail.pay_time }}</div>
					</div>
					<div class="order_infor_content_right">
						<div>{{ $t('order.informationDuration') }}:{{ orderDetail.duration }}</div>
						<!-- <div>{{$t('order.locationHighlight')}}:</div>
						<div>{{$t('order.locationRecommend')}}:</div> -->
						<div class="need_pay">
							<div class="need_pay_left">
								{{ $t('order.accountPayable') }}：
							</div>
							<div class="need_pay_right">
								${{ orderDetail.payable_money }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 支付选择框 -->
		<div class="pay_dialog">
			<el-dialog :title="$t('my.selectPayWay')" :visible.sync="payVisible">
				<div class="pay_mongy">
					<div>{{ $t('pay.amountPayable') }}：<span class="money">${{ total_money }}</span></div>
					<div class="shui">
						（{{ $t('pay.include') }}{{ pay_tax }}{{ $t('pay.taxRate') }}）
					</div>
				</div>

				<div class="change_pay">
					<div class="pay_box" v-for="(item, index) in payList" :key="index" @click="choosePay(item, index)">
						<div class="left">
							<img :src="item.icon" alt="">
							<div>{{ item.pay_type }}</div>
						</div>
						<div class="right" >
							<img src="@/assets/mycenter/is_ok.png" v-if="active == index">
							<img src="@/assets/mycenter/no_ok.png" v-else>
						</div>
					</div>
				</div>

				<div class="btn_pay">
					<button @click="pay_btn">
						{{ $t('pay.payNow') }}
					</button>
				</div>
			</el-dialog>
		</div>

		
	</div>
</template>

<script>
export default {
	data() {
		return {
			order_sn: '', //订单号
			orderDetail: '',
			orderItem: [], //


			messageList: [], //信息列表


			price: '0',
			total_money: '0',
			pay_tax: '0%', //税率
			tax_money: '',// 税后费用

			payVisible: false, //支付方式弹窗
			order_sn: '', // 支付订单
			payList: [], // 支付方式列表
			active: '0',
			pay_type: 'Yappy'
		}
	},
	mounted() {
		this.order_sn = this.$route.query.order_sn;
		this.getAll();
		this.getPayFn()
	},
	methods: {
		getAll() {
			this.$http.orderDetail({
				order_sn: this.order_sn,
			}).then(res => {
				if (res.code == 1) {
					this.orderDetail = res.data;
					this.orderItem = [{
						id: this.orderDetail.id,
						order_sn: this.orderDetail.order_sn,
						title: this.orderDetail.ads_type_name,
						thumb_url: this.orderDetail.image_url,
						payable_money: '$' + this.orderDetail.payable_money,
						status: this.orderDetail.status,
						num: this.orderDetail.num,
					}];

					// this.thumb_url = this.orderDetail.information_info.thumb_url[0].path;


					// console.log(this.thumb_url, "oooo");
					// let nowTime = getCurrentZoneTime(-5); //获取西五区当前的时间戳
					// let startTime = new Date(this.orderDetail.create_time).getTime();
					// let diffTime = nowTime - startTime;
					// this.countDownTime = parseInt((this.restTime - diffTime) / 1000);
					console.log(this.countDownTime);
				} else {
					this.$message.info(res.msg);
				}
			})
		},

		backClick() {
			this.$router.back();
		},

		// 取消订单
		cancelOrderClick() {
			this.$http.cancelOrder({
				order_sn: this.orderDetail.order_sn,
				type: 2,
			}).then(res => {
				if (res.code == 1) {
					this.$message.success(res.msg);
					this.getAll();
				} else {
					this.$message.error(res.msg);
				}
			})

		},

		// 获取支付方式
		getPayFn() {
			this.$http.changePay().then(res => {
				if (res.code == 1) {
					this.payList = res.data
				} else {
					this.$message.info(res.data.msg)
				}
			})
		},
		// 选择支付方式
		choosePay(item, index) {
			console.log(item);
			this.pay_type = item.pay_type
			this.active = index
		},

		pay_btn() {
			if (this.pay_type == 'Paypal') {
				// Paypal
				this.paypalPay(this.order_sn)
			} else if (this.pay_type == 'Yappy') {
				// Yappy
				this.yaPayPay(this.order_sn)
			} else {
				//余额
				this.yuePay(this.order_sn)
			}
		},
		// 去支付
		toPayOrderClick() {
			this.payVisible = true
			// this.$http.getPayPal({
			// 	order_sn: this.orderDetail.order_sn,
			// }).then(res => {
			// 	if (res.code == 1) {
			// 		let payUrl = res.data;
			// 		window.open(payUrl);
			// 	}
			// })
		},
		// 删除订单
		deleteOrderClick() {
			this.$http.orderDel({
				order_sn: this.orderDetail.order_sn,
			}).then(res => {
				if (res.code == 1) {
					this.$message.success(res.msg);
					setTimeout(() => {
						this.$router.push({
							path: '/adOrder',
							query: {
								curCenterIndex: 4,
							}
						})
					}, 1000)
				} else {
					this.$message.error(res.msg);
				}
			})
		},


		// 选择支付方式
		// paypal支付
		paypalPay(orderSn) {
			this.$http.getPayPal({
				order_sn: orderSn,
			}).then(res => {
				if (res.code == 1) {
					this.loading = false;
					this.payVisible = false
					let payUrl = res.data;
					console.log(payUrl, '11233415s');
					window.open(payUrl);
				}else{
					this.$message.error(res.msg)
				}
			})
		},

		// yapay支付 646b400bad167
		yaPayPay(orderSn) {
			this.$http.getYaPay({
				order_sn: orderSn,
			}).then(res => {
				if (res.code == 1) {
					this.loading = false;
					this.payVisible = false
					let payUrl = res.data;
					console.log(payUrl, '11233415s');
					window.open(payUrl);
				}else{
					this.$message.error(res.msg)
				}
			})
		},

		// 余额支付 5e489f45a051d
		yuePay(orderSn) {
			this.$http.getYuePay({
				order_sn: orderSn,
			}).then(res => {
				if (res.code == 1) {
					this.loading = false;
					this.payVisible = false
					this.releaseSuccessVisible = true
					// let payUrl = res.data;
					// console.log(payUrl, '11233415s');
					// window.open(payUrl);
				}else{
					this.$message.error(res.msg)
				}
			})
		},
	}
}
</script>
<style>
/* # background-color为悬停行得背景颜色 ,color为悬停行得字体颜色，其他样式自行添加 */
.el-table tbody tr:hover>td {
	background-color: #FFFFFF !important;
}
</style>
<style scoped="" lang="less">
.step {
	padding: 27px 0 37px;
	display: flex;
	justify-content: space-around;
	background-color: #FFFFFF;
	position: relative;

	.step_item {
		width: 160px;

		.step_item_top {
			img {
				width: 28px;
				height: 28px;
			}
		}

		.step_item_middle {
			position: relative;
			z-index: 400;

			img {
				width: 12px;
				height: 12px;
			}
		}

		.step_item_bottom {
			padding-top: 16px;
			font-size: 14px;
		}
	}

	.line {
		position: absolute;
		top: 67px;
		width: 628px;
		height: 1px;
		background-color: #BFBFBF;
	}

}

.person_infor {
	width: 926px;
}


.login_top {
	display: flex;
	align-items: center;
	padding: 24px;
	border-bottom: 1px solid #F3F5F6;


	.img {
		display: flex;
		align-items: center;

		img {
			width: 18px;
			height: 18px;
		}
	}

	.login_title {
		margin-left: 8px;
		font-weight: bold;
		font-size: 18px;
		color: #F39800;

		&:hover {
			cursor: pointer;
		}
	}

	.front_img {
		display: flex;
		align-items: center;
		margin: 0 12px;

		img {
			width: 8px;
			height: 14px;
		}
	}

	.sub_title {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
	}
}

.order_sn_box {
	display: flex;
	justify-content: space-between;



	.order {
		width: 100%;
		padding-left: 32px;
		text-align: left;
		height: 80px;
		line-height: 80px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		border-bottom: 1px solid #E0E0E0;
	}

	.new_order_sn {

		width: 766px;
		height: 280px;
		background: #FFFFFF;

		.top {
			justify-content: center;
			margin-top: 10px;

			img {
				width: 40px;
				height: 40px;
			}

			div {
				font-size: 20px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #F39800;
			}
		}

		.btn {
			display: flex;
			justify-content: center;
			margin-top: 30px;
			// padding: 0 60px;

			.cancel {
				width: 300px;
				height: 48px;
				background: rgba(246, 247, 249);
				border: 1px solid #DDDDDD;
				border-radius: 4px;
				margin-right: 46px;
			}

			.del {
				width: 300px;
				height: 48px;
				background: rgba(246, 247, 249);
				border: 1px solid #DDDDDD;
				border-radius: 4px;
			}

			.pay {
				width: 300px;
				height: 48px;
				background: #F39800;
				border-radius: 4px;
				border: none;
				color: #fff;
			}
		}


	}

	.gap {
		width: 24px;
		height: 280px;
		background-color: #F6F7F9;

	}

	.new_process {
		width: 766px;
		height: 280px;
		padding: 56px 56px 64px;
		background: #FFFFFF;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		box-sizing: border-box;

		.top {
			display: flex;
			justify-content: left;

			img {
				margin-right: 243px;
				width: 56px;
				height: 56px;
			}
		}

		.center {
			display: flex;
			justify-content: left;
			padding-left: 16px;

			.center_box {
				display: flex;
				justify-content: left;
				align-items: center;
			}

			img {
				width: 32px;
				height: 32px;
			}


			.border {
				width: 265px;
				height: 2px;
				background: rgba(191, 191, 191);
			}
		}

		.bottom {
			display: flex;
			justify-content: space-between;
			font-size: 18px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			// color: #F39800;

			div {
				white-space: nowrap;
				min-width: 72px;

			}

			.pay {
				// margin-right: 260px;
			}


		}
	}
}



// .order_sn {
// 	border: 1px solid #F39800;

// 	.order_sn_top {
// 		padding-left: 48px;
// 		height: 48px;
// 		text-align: left;
// 		line-height: 48px;
// 		background: rgba(230, 0, 18, 0.09);
// 		font-size: 14px;
// 	}

// 	.order_sn_bottom {
// 		padding: 24px 32px;
// 		display: flex;
// 		justify-content: space-between;
// 		align-items: center;

// 		.order_sn_bottom_left {
// 			display: flex;
// 			align-items: center;

// 			.status_img {
// 				img {
// 					width: 113px;
// 					height: 80px;
// 				}
// 			}

// 			.status_text {
// 				margin-left: 24px;
// 				color: #333333;
// 				font-size: 18px;
// 				font-weight: bold;
// 			}
// 		}

// 		.order_sn_bottom_right {
// 			display: flex;


// 			.btn {
// 				display: inline-block;
// 				box-sizing: border-box;
// 				padding: 8px 24px;
// 				min-width: 104px;
// 				border-radius: 16px;
// 				font-size: 14px;
// 				cursor: pointer;
// 			}

// 			.btn_red {
// 				margin-left: 24px;
// 				background-color: #F39800;
// 				color: #FFFFFF;
// 			}

// 			.btn_grey {
// 				background-color: #F6F7F9;
// 				color: #333333;
// 			}
// 		}
// 	}
// }

.gap {
	height: 20px;
	background-color: #F6F7F9;
}

.order_detail {
	background-color: #FFFFFF;
	padding: 24px;
}

.order_infor {
	.order_infor_title {
		font-weight: bold;
		font-size: 16px;
		padding: 32px 0 16px;
		border-bottom: 1px solid #EEEEEE;
		text-align: left;
	}

	.order_infor_content {
		padding-top: 24px;
		display: flex;
		justify-content: space-between;

		.order_infor_content_left {
			text-align: left;

			&>div {
				font-size: 14px;
				margin-bottom: 16px;
			}
		}

		.order_infor_content_right {
			text-align: right;

			&>div {
				font-size: 14px;
				margin-bottom: 16px;
			}

			.need_pay {
				display: flex;
				align-items: center;
				font-size: 16px;
				font-weight: bold;

				.need_pay_left {}

				.need_pay_right {
					color: #E60012;
				}
			}
		}
	}
}

.table {

	.infor {
		display: flex;
		justify-content: left;
		align-items: center;

		.infor_img {
			margin-right: 24px;

			img {
				width: 48px;
				height: 48px;
			}
		}

		.infor_title {
			margin-left: 8px;
			width: 132;
			height: 45px;
			color: #333333;
			font-weight: 500;
			text-overflow: -o-ellipsis-lastline;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
			word-break: break-all;
			word-wrap: break-word;
		}
	}

	.status {
		font-size: 12px;

		&>span:hover {
			cursor: pointer;
		}

		.red {
			color: #F39800;
		}

		.blue {
			color: #4177DD;
		}

		.gray {
			font-size: 14px;
			color: #333;
			width: 88px;
			height: 32px;
			background: #F6F7F9;
			border: 1px solid #DDDDDD;
			border-radius: 4px;
		}

	}

	.operate {
		span {
			font-size: 12px;
			display: inline-block;

			&:hover {
				cursor: pointer;
			}
		}

		.blue {
			margin-left: 8px;
			color: #fff;
			width: 88px;
			height: 32px;
			line-height: 32px;
			background: #F39800;
			border-radius: 4px;
			box-sizing: border-box;
		}

		.gray {
			font-size: 14px;
			color: #333;
			min-width: 88px;
			height: 32px;
			line-height: 32px;
			background: #F6F7F9;
			border: 1px solid #DDDDDD;
			border-radius: 4px;
			box-sizing: border-box;
		}
	}



}

.pay_dialog {
	/deep/ .el-dialog {
		width: 480px !important;

	}

	/deep/ .el-dialog__body {
		max-height: 480px;
		overflow-y: hidden;
	}

	/deep/ .el-dialog__header {
		text-align: left;
		padding: 24px 32px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		border-bottom: 1px solid #DDDDDD;
	}

	.pay_mongy {
		height: 142px;
		border-bottom: 1px solid #DDDDDD;

		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;

		.money {
			font-size: 32px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #E60012;
		}

		.shui {
			margin-top: 12px;
			font-size: 12px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #666666;
		}

	}

	.change_pay {
		border-bottom: 1px solid #DDDDDD;

		.pay_box {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 32px;

			.left {
				display: flex;
				justify-content: left;
				align-items: center;


				img {
					width: 40px;
					height: 40px;
				}

				div {
					margin-left: 20px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}
			}

			.right {
				img {
					width: 20px;
					height: 20px;
				}
			}
		}

		.pay_box:last-child {
			margin-bottom: 32px;
		}
	}

	.btn_pay {
		padding-top: 24px;

		button {
			width: 300px;
			height: 40px;
			background: rgba(243, 152, 0);
			border-radius: 4px;
			border: none;
			color: #fff;
			font-size: 14px;
		}
	}
}
</style>
